import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _6a7b86c2 = () => interopDefault(import('../pages/404.vue' /* webpackChunkName: "pages/404" */))
const _5c508735 = () => interopDefault(import('../pages/brands/index.vue' /* webpackChunkName: "pages/brands/index" */))
const _9984f44a = () => interopDefault(import('../pages/join-the-movement/index.vue' /* webpackChunkName: "pages/join-the-movement/index" */))
const _0329e0e0 = () => interopDefault(import('../pages/our-impact/index.vue' /* webpackChunkName: "pages/our-impact/index" */))
const _30b5c180 = () => interopDefault(import('../pages/programmes/index.vue' /* webpackChunkName: "pages/programmes/index" */))
const _089015ec = () => interopDefault(import('../pages/resources-and-tools/index.vue' /* webpackChunkName: "pages/resources-and-tools/index" */))
const _06ac1c20 = () => interopDefault(import('../pages/stories/index.vue' /* webpackChunkName: "pages/stories/index" */))
const _1a1a0dd1 = () => interopDefault(import('../pages/styleguide.vue' /* webpackChunkName: "pages/styleguide" */))
const _8e70c6c8 = () => interopDefault(import('../pages/about-us/our-team/index.vue' /* webpackChunkName: "pages/about-us/our-team/index" */))
const _4eb66326 = () => interopDefault(import('../pages/brands/former-members/index.vue' /* webpackChunkName: "pages/brands/former-members/index" */))
const _7912ec65 = () => interopDefault(import('../pages/programmes/countries/index.vue' /* webpackChunkName: "pages/programmes/countries/index" */))
const _41af06e3 = () => interopDefault(import('../pages/resources-and-tools/factories/index.vue' /* webpackChunkName: "pages/resources-and-tools/factories/index" */))
const _19affcf4 = () => interopDefault(import('../pages/resources-and-tools/resource-documents/index.vue' /* webpackChunkName: "pages/resources-and-tools/resource-documents/index" */))
const _7aa7c166 = () => interopDefault(import('../pages/resources-and-tools/factories/thank-you.vue' /* webpackChunkName: "pages/resources-and-tools/factories/thank-you" */))
const _452ad648 = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))
const _079947e0 = () => interopDefault(import('../pages/programmes/countries/_slug/index.vue' /* webpackChunkName: "pages/programmes/countries/_slug/index" */))
const _2412869e = () => interopDefault(import('../pages/stories/category/_slug/index.vue' /* webpackChunkName: "pages/stories/category/_slug/index" */))
const _c2dd2f4a = () => interopDefault(import('../pages/resources-and-tools/factories/_factory/contact.vue' /* webpackChunkName: "pages/resources-and-tools/factories/_factory/contact" */))
const _43cb86b0 = () => interopDefault(import('../pages/brands/_slug/index.vue' /* webpackChunkName: "pages/brands/_slug/index" */))
const _21509cbb = () => interopDefault(import('../pages/programmes/_slug/index.vue' /* webpackChunkName: "pages/programmes/_slug/index" */))
const _f686fe76 = () => interopDefault(import('../pages/resources-and-tools/_slug/index.vue' /* webpackChunkName: "pages/resources-and-tools/_slug/index" */))
const _4253f6a6 = () => interopDefault(import('../pages/resources/_slug/index.vue' /* webpackChunkName: "pages/resources/_slug/index" */))
const _27807f5b = () => interopDefault(import('../pages/stories/_slug/index.vue' /* webpackChunkName: "pages/stories/_slug/index" */))
const _26b6808a = () => interopDefault(import('../pages/wp-content/_.vue' /* webpackChunkName: "pages/wp-content/_" */))
const _b5bdad66 = () => interopDefault(import('../pages/ul/_.vue' /* webpackChunkName: "pages/ul/_" */))
const _8253bab8 = () => interopDefault(import('../pages/_slug/_.vue' /* webpackChunkName: "pages/_slug/_" */))
const _2d5de6e9 = () => interopDefault(import('../pages/_.vue' /* webpackChunkName: "pages/_" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/404",
    component: _6a7b86c2,
    name: "404"
  }, {
    path: "/brands",
    component: _5c508735,
    name: "brands"
  }, {
    path: "/join-the-movement",
    component: _9984f44a,
    name: "join-the-movement"
  }, {
    path: "/our-impact",
    component: _0329e0e0,
    name: "our-impact"
  }, {
    path: "/programmes",
    component: _30b5c180,
    name: "programmes"
  }, {
    path: "/resources-and-tools",
    component: _089015ec,
    name: "resources-and-tools"
  }, {
    path: "/stories",
    component: _06ac1c20,
    name: "stories"
  }, {
    path: "/styleguide",
    component: _1a1a0dd1,
    name: "styleguide"
  }, {
    path: "/about-us/our-team",
    component: _8e70c6c8,
    name: "about-us-our-team"
  }, {
    path: "/brands/former-members",
    component: _4eb66326,
    name: "brands-former-members"
  }, {
    path: "/programmes/countries",
    component: _7912ec65,
    name: "programmes-countries"
  }, {
    path: "/resources-and-tools/factories",
    component: _41af06e3,
    name: "resources-and-tools-factories"
  }, {
    path: "/resources-and-tools/resource-documents",
    component: _19affcf4,
    name: "resources-and-tools-resource-documents"
  }, {
    path: "/resources-and-tools/factories/thank-you",
    component: _7aa7c166,
    name: "resources-and-tools-factories-thank-you"
  }, {
    path: "/",
    component: _452ad648,
    name: "index"
  }, {
    path: "/programmes/countries/:slug",
    component: _079947e0,
    name: "programmes-countries-slug"
  }, {
    path: "/stories/category/:slug",
    component: _2412869e,
    name: "stories-category-slug"
  }, {
    path: "/resources-and-tools/factories/:factory?/contact",
    component: _c2dd2f4a,
    name: "resources-and-tools-factories-factory-contact"
  }, {
    path: "/brands/:slug",
    component: _43cb86b0,
    name: "brands-slug"
  }, {
    path: "/programmes/:slug",
    component: _21509cbb,
    name: "programmes-slug"
  }, {
    path: "/resources-and-tools/:slug",
    component: _f686fe76,
    name: "resources-and-tools-slug"
  }, {
    path: "/resources/:slug",
    component: _4253f6a6,
    name: "resources-slug"
  }, {
    path: "/stories/:slug",
    component: _27807f5b,
    name: "stories-slug"
  }, {
    path: "/wp-content/*",
    component: _26b6808a,
    name: "wp-content-all"
  }, {
    path: "/ul/*",
    component: _b5bdad66,
    name: "ul-all"
  }, {
    path: "/:slug/*",
    component: _8253bab8,
    name: "slug-all"
  }, {
    path: "/*",
    component: _2d5de6e9,
    name: "all"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
