import Vue from 'vue';
import { VuePlausible } from 'vue-plausible';

Vue.use(VuePlausible, {
    domain: 'fairwear.org',
    enableAutoPageviews: true,
    enableAutoOutboundTracking: true
});


// <script defer data-domain="fairwear.org" src="https://plausible.io/js/script.outbound-links.tagged-events.js"></script>
// <script>window.plausible = window.plausible || function() { (window.plausible.q = window.plausible.q || []).push(arguments) }</script>
